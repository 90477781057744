.button-reseted-in .button {
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-reseted-in .button .ripple {
  width: 100px !important;
  display: none !important;
}
.button-reseted-in .button .button-label-text {
  white-space: unset !important;
  padding: 0;
  margin: 0;
}
@media all and (min-width: 1200px) {
  .button-reseted-in .button .button-label-text {
    white-space: nowrap !important;
  }
}
