.accordion-section-heading button {
  align-items: flex-start;
}
.accordion-section-heading .button-label-text {
  white-space: unset;
  text-overflow: unset;
  text-align: left;
}
.input-element-search {
  padding-right: 2.5rem !important;
}
.input-element-search:focus {
  border: 0 !important;
}
