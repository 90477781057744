.triangle-with-shadow {
  width: 40px;
  height: 40px;
  position: absolute;

  overflow: hidden;
}
.triangle-with-shadow:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: #182af0;
  transform: rotate(45deg);
  top: 30px;
  left: 5px;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
}

.triangle-with-shadow-scrolled {
  position: absolute;

  overflow: hidden;
  width: 20px;
  height: 20px;
}

.triangle-with-shadow-scrolled:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: #182af0;
  transform: rotate(45deg);
  top: 15px;
  left: 2.5px;
  box-shadow: 1px 1px 1px 0px rgba(0, 0, 0, 0.5);
}
