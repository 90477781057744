.overlay.overlay-clip-container {
    padding: 0;
    align-items: baseline;
    overflow: auto;
  }
  .my-dialog.dialog {
    scrollbar-color: #202945 rgb(251, 251, 251);
    scrollbar-width: thin;
    max-width: 560px !important;
    min-width: unset !important;
  }
  @media all and (min-width: 640px) {
    .overlay.overlay-clip-container {
      padding: 2rem 0;
    }
    .my-dialog.dialog {
      max-width: 560px !important;
      min-width: unset !important;
    }
  }
  @media all and (min-width: 1024px) {
    .my-dialog.dialog {
      max-width: 768px !important;
    }
  }
  @media all and (min-width: 1366px) {
    .my-dialog.dialog {
      max-width: 1024px !important;
    }
  }
  