.modal-reset {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}
.add-banner-date > div {
  min-width: 320px;
  margin-right: 0;
}
body .date-time-input-time-hours-minutes {
  margin-right: 0;
}
